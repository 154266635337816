import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "p-1 rounded-lg shadow-lg scroll align-items-center text-center" }

import { computed } from 'vue';
import { useStore } from 'vuex';
import PairNamePriceCompact from "@/components/PairNamePriceCompact.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'WatchlistDisplay',
  setup(__props) {

const store = useStore();

const watchlist = computed(() => store.getters.getWatchlist);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_CBadge = _resolveComponent("CBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (watchlist.value.length > 0)
      ? (_openBlock(), _createBlock(_component_CBadge, {
          key: 0,
          "color-scheme": "red",
          class: "pulsing-badge"
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createTextVNode(" Watching ")),
            _createVNode(_component_font_awesome_icon, {
              icon: ['fa', 'eye'],
              style: {"color":"#822727"}
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_CBadge, {
          key: 1,
          "color-scheme": "gray",
          style: {"opacity":"0.15"}
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Watcher Offline ")
          ])),
          _: 1
        })),
    _createVNode(_TransitionGroup, {
      name: "slide-fade",
      tag: "ul",
      class: "list-none p-0"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(watchlist.value, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.pair_address,
            boxShadow: "md",
            class: "bg-gray-700 text-white p-3 rounded-md flex items-center justify-between"
          }, [
            _createVNode(PairNamePriceCompact, { pair: item }, null, 8, ["pair"])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})