import { createApp } from 'vue'
import { createStore } from 'vuex'
import pairs from './modules/pairs'
export default createStore({
  state: {
    windowWidth: window.innerWidth,
    watchlist: JSON.parse(localStorage.getItem('watchlist')) || {},
  },
  mutations: {
    setWindowWidth(state) {
      state.windowWidth = window.innerWidth;
    },
    ADD_TO_WATCHLIST(state, item) {
      if (!state.watchlist[item.pair_id]) {
        state.watchlist[item.pair_id] = item;
        localStorage.setItem('watchlist', JSON.stringify(state.watchlist));
      }
    },
    REMOVE_FROM_WATCHLIST(state, pairId) {
      delete state.watchlist[pairId];
      localStorage.setItem('watchlist', JSON.stringify(state.watchlist));
    }
  },
  actions: {
    addToWatchlist({ commit }, item) {
      commit('ADD_TO_WATCHLIST', item);
    },
    removeFromWatchlist({ commit }, pairId) {
      commit('REMOVE_FROM_WATCHLIST', pairId);
    },
  },
  getters: {
    getWatchlist: state => Object.values(state.watchlist),
    isItemInWatchlist: state => pairId => !!state.watchlist[pairId],
  },
  modules: {
    pairs,
  }
});
