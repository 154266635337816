import { createApp } from 'vue';
import VueSidebarMenu from 'vue-sidebar-menu';
import router from './router/index.js';
import store from './store/index.js';
import './fontawesome.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createChakra, extendTheme, CIcon } from "@chakra-ui/vue-next";
import App from './App.vue';
import { extendedTheme } from "./theme.js";
import { Chart, registerables } from 'chart.js';
import { createI18n } from 'vue-i18n';
import  InstantSearch  from 'vue-instantsearch/vue3/es';
import './echo.js';
import Watchlist from "@/components/Watchlist.vue";
import * as ChakraComponents from '@chakra-ui/vue-next';
import { MotionPlugin } from '@vueuse/motion'
import { OhVueIcon, addIcons } from "oh-vue-icons";
import PhoneMenu from '@/components/PhoneMenu.vue';
import {
    CoChevronDoubleUp,
    BiShiftFill,
    FaFlag,
    CoFire,
    IoBeaker,
    HiTrendingUp,
    GiSparkles,
    CoMarketo,
    CoChevronDoubleDown,
    BiArrowReturnRight
} from "oh-vue-icons/icons";
import MobileCells from "@/components/MobileCells.vue";


const numberFormats: { [key: string]: { currency: Intl.NumberFormatOptions; currencyPrice: Intl.NumberFormatOptions } } = {
    'en-US': {
        currency: {
            style: 'currency' as const, // Explicitly set as a constant string literal
            currency: 'USD',
            notation: 'standard',
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
        },
        currencyPrice: {
            style: 'currency' as const, // Explicitly set as a constant string literal
            currency: 'USD',
            notation: 'standard',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
};


Chart.register(...registerables);
const app = createApp(App);
const i18n = createI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    numberFormats,
})

const chakra = createChakra({
    cssReset: true,
    extendTheme: extendedTheme,

});
addIcons(BiArrowReturnRight,CoChevronDoubleDown, CoFire, FaFlag,BiShiftFill, HiTrendingUp, IoBeaker, GiSparkles, CoMarketo, CoChevronDoubleUp);

app.use(store);
app.use(router);
app.use(VueSidebarMenu);
app.component('InstantSearch', InstantSearch);
app.use(MotionPlugin)
app.use(i18n)
app.use(chakra);
app.component("v-icon", OhVueIcon);
app.component('CIcon', CIcon)
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Watchlist', Watchlist);
app.component('PhoneMenu', PhoneMenu);
app.component('MobileCells', MobileCells);

Object.entries(ChakraComponents).forEach(([name, component]) => {
    app.component(name, component);

});app.mount('#app');
