import * as API from "@/services/API";

const state = {
    pairs: JSON.parse(sessionStorage.getItem('pairs')) || {},
    meta: JSON.parse(sessionStorage.getItem('meta')) || {},
    currentPage: 1,
    totalPages: JSON.parse(sessionStorage.getItem('totalPages')) || 0,
    per_page: 25,
    lastUpdated: JSON.parse(sessionStorage.getItem('lastUpdated')) || null,
};

const mutations = {
    SET_PAIRS(state, { page, pairs, meta }) {
        state.pairs[page] = pairs;
        state.meta[page] = meta;

        sessionStorage.setItem('pairs', JSON.stringify(state.pairs));
        sessionStorage.setItem('meta', JSON.stringify(state.meta));
        sessionStorage.setItem('totalPages', meta.last_page);
        sessionStorage.setItem('lastUpdated', JSON.stringify(new Date().getTime())); // track when the data was updated
    },
    SET_CURRENT_PAGE(state, page) {
        state.currentPage = page;
    },
    CLEAR_CACHE(state) {
        state.pairs = {};
        state.meta = {};
        sessionStorage.removeItem('pairs');
        sessionStorage.removeItem('meta');
        sessionStorage.removeItem('totalPages');
        sessionStorage.removeItem('lastUpdated');
    }
};

const actions = {
    async fetchPairsFromCache({ commit, state }, page = 1) {
        try {
            // Check if session has expired (10 minutes expiry)
            const now = new Date().getTime();
            const expiryTime = 10 * 60 * 1000;
            if (state.lastUpdated && now - state.lastUpdated > expiryTime) {
                commit('CLEAR_CACHE');
            }

            const response = await API.apiClient.get(`/pairs?page=${page}`);
            commit('SET_PAIRS', {
                pairs: response.data.data,
                meta: response.data.meta,
                page
            });
        } catch (error) {
            console.error('Error fetching pairs:', error);
        }
    },
    setCurrentPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page);
    }
};

const getters = {
    getPairs(state) {
        return state.pairs;
    },
    getMeta(state) {
        return state.meta;
    },
    getCurrentPage(state) {
        return state.currentPage;
    },
    getTotalPages(state) {
        return state.totalPages;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
