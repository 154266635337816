import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 flex items-center justify-between rounded-lg shadow-md bg-[#15296c]" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2 text-white" }
const _hoisted_4 = { class: "ml-4 text-white text-lg font-semibold" }

import { computed } from 'vue';
import { useStore } from 'vuex';

// Define the types for the props
interface PairJson {
  id: number;
  name: string;
}

interface PairAddress {
  id: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Watchlist',
  props: {
    pair: {},
    item: {}
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const isItemInWatchlist = computed(() => store.getters.isItemInWatchlist(props.pair.id));

const toggleWatchlist = () => {
  if (isItemInWatchlist.value) {
    store.dispatch('removeFromWatchlist', props.pair.id);
  } else {
    store.dispatch('addToWatchlist', props.pair);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_CCheckbox = _resolveComponent("CCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CCheckbox, {
        "is-checked": isItemInWatchlist.value,
        onChange: toggleWatchlist,
        "aria-label": "Add or remove pair from watchlist",
        class: "text-white"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(isItemInWatchlist.value ? 'Remove from Watchlist' : 'Add to Watchlist'), 1)
        ]),
        _: 1
      }, 8, ["is-checked"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.pair.name), 1)
    ])
  ]))
}
}

})