<script setup>
import { useRouter } from 'vue-router';

const props = defineProps({
  pairAddress: {
    type: String,
    required: true
  },
  pairShowUrl: {
    type: Function,
    required: true
  }
});

const router = useRouter();

function navigateToPair(pairAddress) {
  router.push({ path: pairShowUrl(pairAddress) });
}

function pairShowUrl(pairId) {
  return `/pairs/${pairId}`;
}

// Function to truncate pair address to 5 characters
function truncatedAddress(address) {
  return address.length > 5 ? address.substring(0, 7) + '...' : address;
}
</script>

<template>
  <CText fontSize="sm" color="accent1" @click="navigateToPair(pairAddress)" cursor="pointer">
    {{ truncatedAddress(pairAddress) }}
  </CText>
</template>

<style scoped>
</style>
