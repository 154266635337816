<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

// Define the types for the props
interface PairJson {
  id: number;
  name: string;
}

interface PairAddress {
  id: number;
}

const props = defineProps<{
  pair: PairJson;
  item: PairAddress;
}>();

const store = useStore();

const isItemInWatchlist = computed(() => store.getters.isItemInWatchlist(props.pair.id));

const toggleWatchlist = () => {
  if (isItemInWatchlist.value) {
    store.dispatch('removeFromWatchlist', props.pair.id);
  } else {
    store.dispatch('addToWatchlist', props.pair);
  }
};
</script>

<template>
  <div class="p-4 flex items-center justify-between rounded-lg shadow-md bg-[#15296c]">
    <div class="flex items-center">
      <CCheckbox
          :is-checked="isItemInWatchlist"
          @change="toggleWatchlist"
          aria-label="Add or remove pair from watchlist"
          class="text-white"
      >
        <span class="ml-2 text-white">
          {{ isItemInWatchlist ? 'Remove from Watchlist' : 'Add to Watchlist' }}
        </span>
      </CCheckbox>
    </div>
    <div class="ml-4 text-white text-lg font-semibold">
      <h3>{{ pair.name }}</h3>
    </div>
  </div>
</template>

<style scoped>
/* Add any additional styling here if needed */
</style>
