<template>
  <CStack>
    <CBox boxShadow='md' class="centered-box flex items-center justify-between">
      <router-link :to="{path: pairShowUrl(pair.pair_address)}" >
        <span class="flex items-center">
          <CText font-size="xs" color="white">
            {{ (pair.NAME.nonBaseToken) }} / {{ pair.NAME.baseToken }}

          </CText>

          <c-tag size="sm" variant="solid" color-scheme="teal" class="ml-2">
            <CText font-size="xs" p="0" mb="0">
              $0.0
            </CText>
            <CText p="0" pt="2" mb="0" size="xs" font-size="xxs">
              {{ decimaliser.getExponent(pair.PRICE_USD) }}
            </CText>
            <CText font-size="xs" p="0" mb="0">
              {{ decimaliser.getBig(pair.PRICE_USD) }}
            </CText>
          </c-tag>
        </span>
      </router-link>

      <button @click="removeFromWatchlist(pair.pair_id)" class="text-red-500 hover:text-red-700">
        <font-awesome-icon :icon="['fa', 'times']" />
      </button>
    </CBox>
  </CStack>
</template>

<script>
import decimaliser from "@/helpers/decimaliser.js";
export default {
  props: {
    pair: {
      type: Object,
      required: true
    },
    collapsed: Boolean
  },
  computed: {
    decimaliser() {
      return decimaliser;
    }
  },
  methods: {
    removeFromWatchlist(pairId) {
      this.$store.dispatch('removeFromWatchlist', pairId);
    },
    pairShowUrl(pairId) {
      return `/pairs/${pairId}`;
    },
  },
};
</script>

<style scoped>
.centered-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
