import axios from "axios";
import store from "@/store";

const baseURL =
    process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL
        : process.env.VUE_APP_API_URL_DEV;

export const apiClient = axios.create({
  baseURL: baseURL, // Corrected base URL setup
  withCredentials: true,
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (
          error.response &&
          [401, 419].includes(error.response.status) &&
          store.getters["auth/authUser"] &&
          !store.getters["auth/guest"]
      ) {
        store.dispatch("auth/logout");
      }
      return Promise.reject(error);
    }
);
