import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tableAnatomy.keys);

const md = definePartsStyle({
    fontSize: 'xs',

});
const sm = definePartsStyle({
    fontSize: 'xxs',
});

const base = definePartsStyle({
    fontSize: 'xxs',
    paddingBottom: '0'

});

const xs = definePartsStyle({
    fontSize: 'xxs',
});

const lg = definePartsStyle({
    fontSize: 'md',
});

const xl = definePartsStyle({
    fontSize: 'lg',
});

const variants  = definePartsStyle({
    simple: {
        tbody: {
            borderStyle: 'inset',
            borderWidth: '0.25px',
            borderColor: 'blue',
            borderLeftWidth: '0px',
        },
        th: {
            borderStyle: 'inset',
            borderWidth: '0.15px',
            borderColor: "blue",
            borderLeftWidth: '0px',
            borderBottomColor: "blue",
            color: "white",
            fontWeight: "900",


        },
        td: {
            lineHeight: '0.6rem',
            borderStyle: 'inset',
            borderLeftWidth: '0px',
            borderRightWidth: '0.15px',
            borderColor: 'blue'
        },
        tr: {
            borderStyle: 'inset',
            borderWidth: '0.15px',
            borderLeftWidth: '0px',
            borderRightRadius: "full",
            borderColor: "blue",
            borderBottomColor: "blue",
        },


    },
    recentTx : {
        tr: {
            borderStyle: 'inset',
            borderWidth: '0.15px',
            borderLeftWidth: '0px',
            borderRightWidth: '0px',
            borderRightRadius: "full",
            borderColor: "grey",
            borderBottomColor: "grey",
        },
    }

})


const sizes = {
    sm: { th: sm, td: sm, caption: sm, tr: sm },
    md: { th: md, td: md, caption: md, tr: md },
    xs: { th: xs, td: xs, caption: xs, tr: xs },
    lg: { th: lg, td: lg, caption: lg, tr: lg },
    xl: { th: xl, td: xl, caption: xl, tr: xl },
    base: { th: base, td: base, caption: base, tr: base },
};

export const tableTheme = defineMultiStyleConfig({ sizes, variants });
