<template>
  <img
      class="logo"
      :class="{ collapsed: collapsed }"
      :src="collapsed ? getLogoPathCollapsed : getLogoPath"
      alt="Logo"
  />
</template>

<script>
import { defineComponent, computed, watch, onMounted } from "vue";

export default defineComponent({
  name: 'LogoComponent',
  props: {
    collapsed: Boolean,
    componentKey: Number,
  },
  setup(props) {
    const getLogoPath = computed(() => {
      return require('@/assets/Logo_Standard_v2.png');
    });

    const getLogoPathCollapsed = computed(() => {
      return require('@/assets/Logo_Icon.png');
    });

    onMounted(() => {
      console.log(props.collapsed, ' is collapsed in child');
    });

    watch(() => props.collapsed, (newValue, oldValue) => {
      console.log('collapsed prop changed from', oldValue, 'to', newValue);
    });

    watch(() => props.componentKey, (newValue, oldValue) => {
      console.log('componentKey prop changed from', oldValue, 'to', newValue);
    });

    return {
      getLogoPath,
      getLogoPathCollapsed,
    };
  }
});
</script>

<style scoped>
.logo {
  padding: 5px;
  margin-bottom: 0.5rem;
  width: 100%;
  height: auto;
  transition: all 0.3s ease;
}

.logo.collapsed {
  padding: 5px;
  margin-bottom: 0.5rem;
  width: 100%;
  height: auto;
  transition: all 0.3s ease;
}
</style>
