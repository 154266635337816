class decimaliser {
    static getExponent(value) {
        const exponentMatch = value.toExponential().match(/e([+-]?\d+)/);
        if (exponentMatch) {
            const exponent = parseInt(exponentMatch[1]);
            const zerosCount = Math.max(0, Math.abs(exponent) - 1); // Calculate number of zeros
            return zerosCount - 1
        }
        return null;
    }

    static getBig(value) {
        const exponentMatch = value.toExponential().match(/e([+-]?\d+)/);
        let positiveExponent = Math.abs(parseInt(exponentMatch[1]))
        return  Math.round(value * Math.pow(10, positiveExponent+3), 3)
    }

    static two_decimals(value) {
        if (value % 1 === 0) {
            // If the value is an integer, return it with two decimals explicitly.
            return value.toFixed(2);
        } else {
            if (value >= 0.01) {
                // Format with two decimals for values >= 0.01
                return value.toFixed(2);
            } else if (value >= 0.001) {
                // Format with three decimals for values >= 0.001
                return value.toFixed(3);
            } else {
                // Format with four decimals for smaller values
                return value.toFixed(4);
            }
        }
    }
}

export default decimaliser;