import { buttonAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    buttonAnatomy.keys
);

// Define styles for different button sizes
const xs = definePartsStyle({
    fontSize: "xs",
    paddingX: "2",
    paddingY: "1",

});

const sm = definePartsStyle({
    fontSize: "sm",
    paddingX: "3",
    paddingY: "2",


});

const md = definePartsStyle({
    fontSize: "md",
    paddingX: "4",
    paddingY: "2.5",

});

const lg = definePartsStyle({
    fontSize: "lg",
    paddingX: "5",
    paddingY: "3",

});

const xl = definePartsStyle({
    fontSize: "xl",
    paddingX: "6",
    paddingY: "3.5",

});

// Define the sizes object for different button sizes
const sizes = {
    xs: definePartsStyle({ base: xs }),
    sm: definePartsStyle({ base: sm }),
    md: definePartsStyle({ base: md }),
    lg: definePartsStyle({ base: lg }),
    xl: definePartsStyle({ base: xl }),
};

// Define the button theme with responsive sizes
export const buttonTheme = defineMultiStyleConfig({ sizes });