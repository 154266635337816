<template>
  <CBox
      position="fixed"
      bottom="0"
      left="10"
      right="10"
      rounded="md"
      bg="rgba(21, 41, 108, 0.8)"
      shadow="md"
      z-index="sticky"
      display="block"
      borderColor="gray.200"
      >
    <CStack direction="row" justify="space-around" align="center" spacing="4" p="2">
      <router-link to="/pairs">
        <font-awesome-icon :icon="['fa', 'fire']" style="color: white;" />
      </router-link>

      <router-link to="/pairs">
        <font-awesome-icon :icon="['fab', 'ethereum']" style="color: white;" />
      </router-link>

      <router-link to="/pairs">
        <font-awesome-icon :icon="['fa', 'code']" style="color: white;" />
      </router-link>

    </CStack>
  </CBox>
</template>

<script>
import { defineComponent } from 'vue';
import { CBox, CIconButton, CStack } from '@chakra-ui/vue-next';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    CBox,
    CIconButton,
    CStack,
    FontAwesomeIcon,
  },
  setup() {
    const navigateTo = (route) => {
      // Replace this with your Vue Router navigation logic
      console.log(`Navigating to ${route}`);
    };

    return {navigateTo};
  },
});
</script>

<style scoped>
/* You can add custom styles here if needed */
</style>
