<script setup>
import { ref, onMounted, onUnmounted } from "vue";

// Define props for Unix timestamp
const props = defineProps({
  pair_age_as_unix_timestamp: {
    type: Number,
    required: true,
  }
});

const elapsedTime = ref("");

const calculateTimeDifference = () => {
  if (!props.pair_age_as_unix_timestamp) { 
    return 'N/A'; 
  }

  const now = Math.floor(Date.now() / 1000); // Get current time in Unix format (seconds)
  const timestamp = parseInt(props.pair_age_as_unix_timestamp, 10);
  const diffInSeconds = now - timestamp;

  const months = Math.floor(diffInSeconds / (30 * 24 * 3600));
  const days = Math.floor((diffInSeconds % (30 * 24 * 3600)) / (24 * 3600));
  const hours = Math.floor((diffInSeconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  // Determine the two largest units to display
  let elapsedTimeText = "";
  if (months > 0) {
    elapsedTimeText = `${months}m ${days}d`;
  } else if (days > 0) {
    elapsedTimeText = `${days}d ${hours}h`;
  } else if (hours > 0) {
    elapsedTimeText = `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    elapsedTimeText = `${minutes}m ${seconds}s`;
  } else {
    elapsedTimeText = `${seconds}s`;
  }

  elapsedTime.value = elapsedTimeText;
};

let interval;

onMounted(() => {
  calculateTimeDifference();
  interval = setInterval(calculateTimeDifference, 1000); // Update every second
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>
  <CText fontSize="sm" color="white">
    {{ elapsedTime }}
  </CText>
</template>

<style scoped>
</style>
