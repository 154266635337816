<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import PairNamePriceCompact from "@/components/PairNamePriceCompact.vue";
const store = useStore();

const watchlist = computed(() => store.getters.getWatchlist);
</script>

<template>
  <div class="p-1 rounded-lg shadow-lg scroll align-items-center text-center">
    <CBadge color-scheme="red" class="pulsing-badge" v-if="watchlist.length > 0">
      Watching
      <font-awesome-icon :icon="['fa', 'eye']" style="color: #822727;" />
    </CBadge>
    <CBadge v-else color-scheme="gray" style="opacity: 0.15;">
      Watcher Offline
    </CBadge>

    <transition-group name="slide-fade" tag="ul" class="list-none p-0">
      <li
          v-for="item in watchlist"
          :key="item.pair_address"
          boxShadow='md'
          class="bg-gray-700 text-white p-3 rounded-md flex items-center justify-between"
      >
        <PairNamePriceCompact :pair="item" />
      </li>
    </transition-group>
  </div>
</template>

<style scoped>
.scroll {
  max-height: 20vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.pulsing-badge {
  animation: pulse-bg 3s infinite;
}

@keyframes pulse-bg {
  0%, 100% {
    background-color: #fed7d7; /* Lighter red */
  }
  50% {
    background-color: #f56d6d; /* Darker red */
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
