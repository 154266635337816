import Pusher from 'pusher-js';
import { ref } from 'vue';

window.Pusher = Pusher;

const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
});

const swapData = ref([]);
const currentPrice = ref([]);
const currentPriceBase = ref([]);
const tokenReserves = ref([]);
const baseReserves = ref([]);
const contractBalance = ref([]);
const ownerBalance = ref([]);
const deployerBalance = ref([]);

const subscribeToSwapChannel = (channelName, pair) => {
    const channel = pusher.subscribe(channelName);

    channel.bind('swap', (event) => {
        let data = JSON.parse(event.message);
        data = formatSwap(data, pair)
        swapData.value = [data];
    });

    channel.bind('post_block', (post_event) => {
        console.log(post_event.message);
        let data = JSON.parse(post_event.message);
        console.log(data.data);
        console.log(data.data.price_in_usd);
        currentPrice.value = data.data.price_in_usd;
        currentPriceBase.value = data.data.price_in_base;
        tokenReserves.value = data.data.reserves?.token;
        baseReserves.value = data.data.reserves?.base;
        contractBalance.value = data.data.balances?.token;
        ownerBalance.value = data.data.balances?.owner;
        deployerBalance.value = data.data.balances?.deployer;
        console.log(currentPrice);
        console.log(currentPriceBase);
        console.log(tokenReserves);
    })

    return channel;
};

// const startPriceUpdates = () => {
//     setInterval(() => {
//         // Mocking an event with a new price
//         const newPrice = Math.floor(Math.random() * 1000); // Mock a random price for testing
//         const newTokenReserves = Math.floor(Math.random() * 1000000000000);
//         const newBaseReserves = Math.floor(Math.random() * 1000000000000000000000);
//         const newContractBalance = Math.floor(Math.random() * 1000000000000000);
//         const newOwnerBalance = Math.floor(Math.random() * 1000000000000000000);
//         const newDeployerBalance = Math.floor(Math.random() * 1000000000000000);

//         // Simulating the price update event, just like how Echo would handle a real update
//         currentPrice.value = newPrice;
//         tokenReserves.value = newTokenReserves;
//         baseReserves.value = newBaseReserves;
//         contractBalance.value = newContractBalance;
//         ownerBalance.value = newOwnerBalance;
//         deployerBalance.value = newDeployerBalance;
//     }, 5000); // Update every second for testing
// };

// startPriceUpdates()

const unsubscribeFromChannel = (channel) => {
    if (channel) {
        pusher.unsubscribe(channel.name);
    }
};

function getBaseAmount(swap, pair) {
    const transactionType = getTypeOfTransaction(swap.type);
    const tokenOrder = pair.token_order;

    if (transactionType === 'Buy') {
        return tokenOrder === 0
            ? swap.reserve_in / (10 ** pair.token1Decimals)
            : swap.reserve_in / (10 ** pair.token0Decimals);
    } else if (transactionType === 'Sell') {
        return tokenOrder === 0
            ? swap.reserve_out / (10 ** pair.token1Decimals)
            : swap.reserve_out / (10 ** pair.token0Decimals);
    } else {
        return tokenOrder === 0
            ? swap.reserve_out / (10 ** pair.token1Decimals)
            : swap.reserve_in / (10 ** pair.token0Decimals);
    }
}

function getTypeOfTransaction(type) {
    const typeMap = new Map([
        [0, 'Buy'],
        [1, 'Sell'],
        [2, 'ADD'],
        [3, 'REM']
    ]);

    return typeMap.get(type) || 'Unknown'; // Default to 'Unknown' if type not found
}

function getTokenAmount(swap, pair) {
    const transactionType = getTypeOfTransaction(swap.type);
    const tokenOrder = pair.token_order;

    if (transactionType === 'Buy') {
        return tokenOrder === 0
            ? swap.reserve_out / (10 ** pair.token0Decimals)
            : swap.reserve_out / (10 ** pair.token1Decimals);
    } else if (transactionType === 'Sell') {
        return tokenOrder === 0
            ? swap.reserve_in / (10 ** pair.token0Decimals)
            : swap.reserve_in / (10 ** pair.token1Decimals);
    } else {
        return tokenOrder === 0
            ? swap.reserve_in / (10 ** pair.token0Decimals)
            : swap.reserve_out / (10 ** pair.token1Decimals);
    }
}


const formatSwap = (swapData, pair) => {
    if (!swapData || typeof swapData !== 'object') {
        throw new Error("Invalid swap data");
    }

    let formattedData =
        {

            formatted_timestamp: swapData.block_timestamp,

            base_amount: getBaseAmount(swapData, pair) || 'N/A',
            block_number: swapData.block_number || 'N/A',
            id: swapData.swap_id != null ? swapData.swap_id : 'N/A',
            log_index: swapData.log_index || 'N/A',
            maker_address: swapData.maker_address || 'N/A',

            price_in_usd: swapData.price_in_usd,
            volume_in_usd: swapData.volume_in_usd,

            // token_order: swapData.token_order != null ? swapData.token_order : 'N/A',
            // token_0_symbol: swapData.token_0_symbol || 'N/A',
            // token_1_symbol: swapData.token_1_symbol || 'N/A',
            token_amount: getTokenAmount(swapData, pair) || 'N/A',
            total_price_of_swap: swapData.total_price_of_swap !== undefined
                ? `$${swapData.total_price_of_swap}`
                : 'N/A',
            transaction_index: swapData.transaction_index || 'N/A',
            // transaction_volume: swapData.transaction_volume !== undefined
            //     ? `$${swapData.transaction_volume}`
            //     : 'N/A',
            tx_hash: swapData.tx_hash || 'N/A',
            type: swapData.type !== undefined ? swapData.type : 'N/A'
        }
    return formattedData;
};

export { subscribeToSwapChannel, unsubscribeFromChannel, swapData, currentPrice, currentPriceBase, tokenReserves, baseReserves, contractBalance, ownerBalance, deployerBalance };
