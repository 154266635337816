<script setup lang="js">
import PairAge from "@/components/PairAge.vue";
import PairShowUrlAsPairAddress from "@/components/PairShowUrlAsPairAddress.vue";


const props = defineProps({
  index: Number,
  item: Array,
});

function getRandomColor() {
  const colors = ['red', 'green'];
  return colors[Math.floor(Math.random() * colors.length)];
}

const tradingColor = getRandomColor();
</script>

<template>
  <c-box class="washed-purple-bg" :p="2" max-height="35vh" overflow-y="scroll">
    <c-simple-grid columns="2" spacing-y="2" max-width="100%" :p="0">
      <c-grid-item>
        <c-tag
            :class="`futuristic-card ${tradingColor}`"
            background-color="primary"
            text-color="secondary"
        >
          <c-text fontSize="sm" color="accent4">{{ item.non_base }} /</c-text>
          <c-text fontSize="sm" color="secondary">&nbsp;{{ item.base }}</c-text>
          <c-text v-if="item.base === 'WETH'">
            <font-awesome-icon fontSize="sm" :icon="['fab', 'ethereum']" style="color: #5ae07e; margin-left: 5px;" />
          </c-text>
        </c-tag>
      </c-grid-item>

      <c-grid-item>
        <c-tag class="futuristic-card" background-color="primary">
          <c-text fontSize="sm" color="accent1" is-truncated>${{ item.price }}</c-text>
        </c-tag>
      </c-grid-item>

      <c-grid-item>
        <c-tag class="futuristic-card" background-color="buy1" mr="1">
          <c-text fontSize="sm" color="secondary" is-truncated>Buys</c-text>
        </c-tag>
        <c-tag class="futuristic-card" background-color="green">
          <c-text fontSize="sm" color="secondary" is-truncated>{{ item.buy_count }}</c-text>
        </c-tag>
      </c-grid-item>

      <c-grid-item>
        <c-tag class="futuristic-card" background-color="red" mr="1">
          <c-text fontSize="sm" color="secondary" is-truncated>Sells</c-text>
        </c-tag>
        <c-tag class="futuristic-card" background-color="primary">
          <c-text fontSize="sm" color="secondary" is-truncated>{{ item.sell_count }}</c-text>
        </c-tag>
      </c-grid-item>

      <c-grid-item>
        <c-tag class="futuristic-card" background-color="red" mr="1">
          <c-text fontSize="sm" color="secondary" is-truncated>Age</c-text>
        </c-tag>
        <c-tag class="futuristic-card" background-color="primary">
          <c-text fontSize="sm" color="secondary" is-truncated>
            <PairAge :pair_age_as_unix_timestamp="item.time" />
          </c-text>
        </c-tag>
      </c-grid-item>
      <c-grid-item>
        <c-tag class="futuristic-card" background-color="primary">
          <PairShowUrlAsPairAddress :pairAddress="item.pair_address" :pairShowUrl="pairShowUrl" />
        </c-tag>
      </c-grid-item>
    </c-simple-grid>
  </c-box>
</template>

<style scoped>
.washed-purple-bg {
  background: linear-gradient(135deg, rgb(21, 41, 108), rgb(19, 27, 61));
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  overflow: hidden;
}

.washed-purple-bg::-webkit-scrollbar {
  display: none;
}

.washed-purple-bg {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.futuristic-card {
  position: relative; /* Required for the pseudo-element positioning */
  background: linear-gradient(135deg, rgba(19, 27, 61, 0.9), rgba(21, 41, 108, 0.9));
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.futuristic-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
}

/* Glow line styles */
.futuristic-card.red::after,
.futuristic-card.green::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%; /* Center vertically */
  height: 4px; /* Thickness of the glow line */
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.8); /* Red glow for sells */
  opacity: 0.8; /* Slightly transparent */
}

.futuristic-card.green::after {
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8); /* Green glow for buys */
}


</style>